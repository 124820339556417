import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';

import { setModoTela, initForm, importarArquivo, excluirArquivo, limparAcesso, salvar } from './clienteActions';

class ClienteForm extends Component {

	state = { file: null };

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir || this.props.visualizar || this.props.reservarConvite ? 'readOnly' : '';

		if (!this.props.formularioValues.valor_forma_pagamento || this.props.formularioValues.valor_forma_pagamento == 0) {

			let itens = this.props.formularioValues.itens || [];
			let valor = 2340;
			if (itens.length == 0) {
				for (var i = 0; i < itens.length; i++) {
					let item = itens[i];

					valor -= item.valor;
				}

				if (valor > 0) {
					this.props.initForm({
						...this.props.formularioValues,
						valor_forma_pagamento: valor
					});
				}
			}
		}

		let countFormaPagamento = 0;

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Button
						text='Voltar'
						type={'warning'}
						icon={'fa fa-chevron-left'}
						event={() => this.props.setModoTela('lista')} />

					{!this.props.excluir && !this.props.visualizar 
					&& this.props.formularioValues.id  &&  this.props.formularioValues.acesso_email ? 
							<Button
							text='Limpar Acesso App'
							type={'success'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.limparAcesso(this.props.formularioValues)} />
						: null
					}
					<br />

					{this.props.reservarConvite ? (
						<Row>
							<Field
								name='data_reserva_convite'
								component={LabelAndInputMask}
								mask='99/99/9999'
								label='Data da Reserva'
								placeholder='Informe a data da reserva'
								cols='12 6 4 3'
								readOnly={this.props.reservarConvite ? '' : readOnly} />
						</Row>
					) : null}
					<Row>

						<Select
							name='id_status'
							component={LabelAndInput}
							label='Status'
							placeholder='Informe o status'
							cols='12 6 4 4'
							readOnly={readOnly}
							options={this.props.listaClienteStatus}
							value={this.props.formularioValues.id_status}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_status: value
								});
							}} />

						{this.props.formularioValues.id_tipo == 1 ? (
							<Field
								name='numero_contrato'
								component={LabelAndInput}
								label='Número'
								placeholder='Informe o número'
								cols='12 6 4 4'
								readOnly={readOnly} />
						) : (this.props.formularioValues.id_tipo == 2 ? (
							<Field
								name='numero_convite'
								component={LabelAndInput}
								label='Número'
								placeholder='Informe o número'
								cols='12 6 4 4'
								readOnly={readOnly} />
						) : null)}

						{this.props.formularioValues.id_tipo == 1 && 
						<Field
							name='quitado'
							component={LabelAndCheckbox}
							label='Quitado'
							placeholder='Informe'
							cols='12 4 2 1'
							readOnly={readOnly} />}

					</Row>

					<Row>
						<Field
							name='observacao1'
							component={LabelAndInput}
							label='Observação 1'
							placeholder='Informe a observação'
							cols='12 12 6 6'
							readOnly={readOnly} />

						<Field
							name='observacao2'
							component={LabelAndInput}
							label='Observação 2'
							placeholder='Informe a observação'
							cols='12 12 6 6'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Select
							name='id_representante'
							component={LabelAndInput}
							label='Representante'
							placeholder='Informe o representante'
							cols='12 6 6 4'
							readOnly={readOnly}
							options={this.props.listaUsuario}
							value={this.props.formularioValues.id_representante}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_representante: value
								});
							}} />

						<Select
							name='id_vendedor'
							component={LabelAndInput}
							label='Vendedor'
							placeholder='Informe o vendedor'
							cols='12 6 6 4'
							readOnly={readOnly}
							options={this.props.listaUsuario}
							value={this.props.formularioValues.id_vendedor}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_vendedor: value
								});
							}} />
					</Row>
					<Row>
						<Select
							name='id_estado_venda'
							component={LabelAndInput}
							label='Estado da Venda'
							placeholder='Informe o estado'
							cols='12 6 4 4'
							readOnly={readOnly}
							options={this.props.listaEstado}
							value={this.props.formularioValues.id_estado_venda}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_estado_venda: value
								});
							}} />

						<Select
							name='id_cidade_venda'
							component={LabelAndInput}
							label='Cidade da Venda'
							placeholder='Informe a cidade'
							cols='12 6 4 5'
							readOnly={readOnly}
							options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado_venda)}
							value={this.props.formularioValues.id_cidade_venda}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_cidade_venda: value
								});
							}} />

						<Field
							name='data_venda'
							component={LabelAndInputMask}
							mask='99/99/9999'
							label='Data da Venda'
							placeholder='Informe a data da venda'
							cols='12 6 4 3'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome'
							placeholder='Informe o nome'
							cols='12 12 6 6'
							readOnly={readOnly} />

						{this.props.formularioValues.id_tipo == 1 ? (
							<Field
								name='data_nascimento'
								component={LabelAndInputMask}
								mask='99/99/9999'
								label='Data de Nascimento'
								placeholder='Informe a data de nascimento'
								cols='12 6 3 3'
								readOnly={readOnly} />
						) : null}

						<Field
							name='cpf'
							component={LabelAndInputMask}
							mask='999.999.999-99'
							label='CPF'
							placeholder='Informe o CPF'
							cols='12 6 3 3'
							readOnly={readOnly} />
					</Row>
					{this.props.formularioValues.id_tipo == 1 ? (
						<Row>
							<Field
								name='rg'
								component={LabelAndInputMask}
								mask='**********'
								label='RG'
								placeholder='Informe o RG'
								cols='12 4 4 4'
								readOnly={readOnly} />

							<Select
								name='id_estado_civil'
								label='Estado Civil'
								cols='12 4 4 4'
								placeholder='Informe o estado civil'
								readOnly={readOnly}
								options={this.props.listaEstadoCivil}
								value={this.props.formularioValues.id_estado_civil}
								onChange={(value) => {
									this.props.initForm({
										...this.props.formularioValues,
										id_estado_civil: value
									});
								}} />

							<Field
								name='profissao'
								component={LabelAndInput}
								label='Profissão'
								placeholder='Informe a profissão'
								cols='12 4 4 4'
								readOnly={readOnly} />
						</Row>
					) : null}
					{this.props.formularioValues.id_tipo == 1 ? (
						<Row>
							<Field
								name='email'
								component={LabelAndInput}
								label='E-mail'
								placeholder='Informe o e-mail'
								cols='12 5 4 4'
								readOnly={readOnly} />

							<Field
								name='endereco'
								component={LabelAndInput}
								label='Endereço Residencial'
								placeholder='Informe o endereço'
								cols='12 7 8 8'
								readOnly={readOnly} />
						</Row>
					) : null}
					<Row>
						{this.props.formularioValues.id_tipo == 1 ? (
							<Field
								name='bairro'
								component={LabelAndInput}
								label='Bairro'
								placeholder='Informe o bairro'
								cols='12 4 4 3'
								readOnly={readOnly} />
						) : null}

						<Select
							name='id_estado'
							component={LabelAndInput}
							label='Estado'
							placeholder='Informe o estado'
							cols='12 4 4 2'
							readOnly={readOnly}
							options={this.props.listaEstado}
							value={this.props.formularioValues.id_estado}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_estado: value
								});
							}} />

						<Select
							name='id_cidade'
							component={LabelAndInput}
							label='Cidade'
							placeholder='Informe a cidade'
							cols='12 4 4 3'
							readOnly={readOnly}
							options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado)}
							value={this.props.formularioValues.id_cidade}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_cidade: value
								});
							}} />

						{this.props.formularioValues.id_tipo == 1 ? (
							<Field
								name='cep'
								component={LabelAndInputMask}
								mask='99999-999'
								label='CEP'
								placeholder='Informe o CEP'
								cols='12 4 4 2'
								readOnly={readOnly} />
						) : null}

						<Field
							name='telefone_fixo'
							component={LabelAndInputMask}
							mask='(99) 9999-9999'
							label='Telefone Fixo'
							placeholder='Informe o telefone'
							cols='12 4 4 2'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='celular1'
							component={LabelAndInputMask}
							mask='(99) 99999-9999'
							label='Celular'
							placeholder='Informe o celular'
							cols='12 4 4 2'
							readOnly={readOnly} />

						{/*<Select
							name='id_operadora1'
							component={LabelAndInput}
							label='Operadora'
							placeholder='Informe a operadora'
							cols='12 6 6 4'
							readOnly={readOnly}
							options={this.props.listaOperadoraTelefonica}
							value={this.props.formularioValues.id_operadora1}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_operadora1: value
								});
							}} />
					</Row>
					<Row>*/}
						<Field
							name='celular2'
							component={LabelAndInputMask}
							mask='(99) 99999-9999'
							label='Celular'
							placeholder='Informe o celular'
							cols='12 4 4 2'
							readOnly={readOnly} />

						{/*}
						<Select
							name='id_operadora2'
							component={LabelAndInput}
							label='Operadora'
							placeholder='Informe a operadora'
							cols='12 6 6 4'
							readOnly={readOnly}
							options={this.props.listaOperadoraTelefonica}
							value={this.props.formularioValues.id_operadora2}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_operadora2: value
								});
							}} />
							*/}
					
						{this.props.formularioValues.id_tipo == 1 &&						
						<Select
							name='forma_contrato'
							label='Contrato'
							placeholder='Informe a operadora'
							cols='12 6 6 6'
							readOnly={readOnly}
							options={this.props.listaFormaContrato}
							value={this.props.formularioValues.forma_contrato}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									forma_contrato: value
								});
							}} />}
					</Row>

					{this.props.formularioValues.id_tipo == 1 &&
					<Row style={{ backgroundColor: '#c4c2c2' }}>
						<Field
							name='titular2'
							component={LabelAndInput}
							label='Segundo Titular'
							placeholder='Informe o nome'
							cols='12 4 4 4'
							readOnly={readOnly} />
							
						<Field
							name='titular_cpf2'
							component={LabelAndInputMask}
							mask='999.999.999-99'
							label='CPF'
							placeholder='Informe o CPF'
							cols='6 3 3 3'
							readOnly={readOnly} />
							
						<Field
							name='titular_celular2'
							component={LabelAndInputMask}
							mask='(99) 99999-9999'
							label='Celular'
							placeholder='Informe o celular'
							cols='6 2 2 2'
							readOnly={readOnly} />

						<Field
							name='titular_data_nascimento2'
							component={LabelAndInputMask}
							mask='99/99/9999'
							label='Data Nascimento'
							placeholder='Informe a data'
							cols='6 2 2 2'
							readOnly={readOnly} />
							
						<Field
							name='titular_email2'
							component={LabelAndInput}
							label='E-mail'
							placeholder='Informe o e-mail'
							cols='12 3 3 3'
							readOnly={readOnly} />

						<Field
							name='titular_endereco2'
							component={LabelAndInput}
							label='Endereço'
							placeholder='Informe o endereço'
							cols='12 8 8 8'
							readOnly={readOnly} />
					</Row>}

					{this.props.formularioValues.id_tipo == 1 ? (
						<Row style={{ backgroundColor: '#e0e0e0' }}>
							<Field
								name='dependente1'
								component={LabelAndInput}
								label='1 - Dependentes para Benefício'
								placeholder='Informe o dependente'
								cols='12 4 4 4'
								readOnly={readOnly} />

							<Field
								name='dependente_cpf1'
								component={LabelAndInputMask}
								mask='999.999.999-99'
								label='CPF'
								placeholder='Informe o CPF'
								cols='6 3 3 3'
								readOnly={readOnly} />
							
							<Field
								name='dependente_celular1'
								component={LabelAndInputMask}
								mask='(99) 99999-9999'
								label='Celular'
								placeholder='Informe o celular'
								cols='6 2 2 2'
								readOnly={readOnly} />

							<Field
								name='dependente_data_nascimento1'
								component={LabelAndInputMask}
								mask='99/99/9999'
								label='Data Nascimento'
								placeholder='Informe a data'
								cols='6 2 2 2'
								readOnly={readOnly} />
						
							<Field
								name='dependente_email1'
								component={LabelAndInput}
								label='E-mail'
								placeholder='Informe o e-mail'
								cols='12 3 3 3'
								readOnly={readOnly} />

							<Field
								name='dependente_endereco1'
								component={LabelAndInput}
								label='Endereço'
								placeholder='Informe o endereço'
								cols='12 8 8 8'
								readOnly={readOnly} />
						</Row>
					) : null}
					{this.props.formularioValues.id_tipo == 1 ? (
						<Row style={{ backgroundColor: '#f0f0f0' }}>
							<Field
								name='dependente2'
								component={LabelAndInput}
								label='2 - Dependentes para Benefício'
								placeholder='Informe o dependente'
								cols='12 4 4 4'
								readOnly={readOnly} />
								
							<Field
								name='dependente_cpf2'
								component={LabelAndInputMask}
								mask='999.999.999-99'
								label='CPF'
								placeholder='Informe o CPF'
								cols='6 3 3 3'
								readOnly={readOnly} />
							
							<Field
								name='dependente_celular2'
								component={LabelAndInputMask}
								mask='(99) 99999-9999'
								label='Celular'
								placeholder='Informe o celular'
								cols='6 2 2 2'
								readOnly={readOnly} />

							<Field
								name='dependente_data_nascimento2'
								component={LabelAndInputMask}
								mask='99/99/9999'
								label='Data Nascimento'
								placeholder='Informe a data'
								cols='6 2 2 2'
								readOnly={readOnly} />
								
							<Field
								name='dependente_email2'
								component={LabelAndInput}
								label='E-mail'
								placeholder='Informe o e-mail'
								cols='12 3 3 3'
								readOnly={readOnly} />

							<Field
								name='dependente_endereco2'
								component={LabelAndInput}
								label='Endereço'
								placeholder='Informe o endereço'
								cols='12 8 8 8'
								readOnly={readOnly} />
						</Row>
					) : null}
					{this.props.formularioValues.id_tipo == 1 ? (
						<Row style={{ backgroundColor: '#e0e0e0' }}>
							<Field
								name='dependente3'
								component={LabelAndInput}
								label='3 - Dependente para Benefício'
								placeholder='Informe o dependente'
								cols='12 4 4 4'
								readOnly={readOnly} />
								
							<Field
								name='dependente_cpf3'
								component={LabelAndInputMask}
								mask='999.999.999-99'
								label='CPF'
								placeholder='Informe o CPF'
								cols='6 3 3 3'
								readOnly={readOnly} />
							
							<Field
								name='dependente_celular3'
								component={LabelAndInputMask}
								mask='(99) 99999-9999'
								label='Celular'
								placeholder='Informe o celular'
								cols='6 2 2 2'
								readOnly={readOnly} />

							<Field
								name='dependente_data_nascimento3'
								component={LabelAndInputMask}
								mask='99/99/9999'
								label='Data Nascimento'
								placeholder='Informe a data'
								cols='6 2 2 2'
								readOnly={readOnly} />

							<Field
								name='dependente_email3'
								component={LabelAndInput}
								label='E-mail'
								placeholder='Informe o e-mail'
								cols='12 3 3 3'
								readOnly={readOnly} />

							<Field
								name='dependente_endereco3'
								component={LabelAndInput}
								label='Endereço'
								placeholder='Informe o endereço'
								cols='12 8 8 8'
								readOnly={readOnly} />
						</Row>
					) : null}
					{this.props.formularioValues.id_tipo == 1 ? (
						<Row style={{ backgroundColor: '#f0f0f0' }}>
							<Field
								name='dependente4'
								component={LabelAndInput}
								label='4 - Dependente para Benefício'
								placeholder='Informe o dependente'
								cols='12 4 4 4'
								readOnly={readOnly} />
								
							<Field
								name='dependente_cpf4'
								component={LabelAndInputMask}
								mask='999.999.999-99'
								label='CPF'
								placeholder='Informe o CPF'
								cols='6 3 3 3'
								readOnly={readOnly} />
							
							<Field
								name='dependente_celular4'
								component={LabelAndInputMask}
								mask='(99) 99999-9999'
								label='Celular'
								placeholder='Informe o celular'
								cols='6 2 2 2'
								readOnly={readOnly} />

							<Field
								name='dependente_data_nascimento4'
								component={LabelAndInputMask}
								mask='99/99/9999'
								label='Data Nascimento'
								placeholder='Informe a data'
								cols='6 2 2 2'
								readOnly={readOnly} />

							<Field
								name='dependente_email4'
								component={LabelAndInput}
								label='E-mail'
								placeholder='Informe o e-mail'
								cols='12 3 3 3'
								readOnly={readOnly} />

							<Field
								name='dependente_endereco4'
								component={LabelAndInput}
								label='Endereço'
								placeholder='Informe o endereço'
								cols='12 8 8 8'
								readOnly={readOnly} />
						</Row>
					) : null}
					{this.props.formularioValues.id_tipo == 1 ? (
						<Row style={{ backgroundColor: '#e0e0e0' }}>
							<Field
								name='dependente5'
								component={LabelAndInput}
								label='5 - Dependente para Benefício'
								placeholder='Informe o dependente'
								cols='12 4 4 4'
								readOnly={readOnly} />
							
							<Field
								name='dependente_cpf5'
								component={LabelAndInputMask}
								mask='999.999.999-99'
								label='CPF'
								placeholder='Informe o CPF'
								cols='6 3 3 3'
								readOnly={readOnly} />
							
							<Field
								name='dependente_celular5'
								component={LabelAndInputMask}
								mask='(99) 99999-9999'
								label='Celular'
								placeholder='Informe o celular'
								cols='6 2 2 2'
								readOnly={readOnly} />

							<Field
								name='dependente_data_nascimento5'
								component={LabelAndInputMask}
								mask='99/99/9999'
								label='Data Nascimento'
								placeholder='Informe a data'
								cols='6 2 2 2'
								readOnly={readOnly} />

							<Field
								name='dependente_email5'
								component={LabelAndInput}
								label='E-mail'
								placeholder='Informe o e-mail'
								cols='12 3 3 3'
								readOnly={readOnly} />

							<Field
								name='dependente_endereco5'
								component={LabelAndInput}
								label='Endereço'
								placeholder='Informe o endereço'
								cols='12 8 8 8'
								readOnly={readOnly} />
						</Row>
					) : null}

					<Row>
						<Field
							name='observacao'
							component={LabelAndTextarea}
							label='Observação'
							placeholder='Informe a observação'
							cols='12 12 12 12'
							readOnly={readOnly} />
					</Row>
					{this.props.formularioValues.id_tipo == 1 ? (
						<Row>
							<Select
								name='id_tipo_contrato'
								component={LabelAndInput}
								label='Tipo de Contrato'
								placeholder='Informe o tipo de contrato'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaTipoContrato}
								value={this.props.formularioValues.id_tipo_contrato}
								onChange={(value) => {
									this.props.initForm({
										...this.props.formularioValues,
										id_tipo_contrato: value
									});
								}} />
						</Row>
					) : null}

					{this.props.formularioValues.id_tipo == 1 &&
					<Row>
						<Field
							name='serasa'
							component={LabelAndCheckbox}
							label='Serasa'
							placeholder='Informe'
							cols='12 4 2 1'
							readOnly={readOnly} />

						{this.props.formularioValues.serasa &&
						<Field
							name='data_serasa'
							component={LabelAndInputMask}
							mask='99/99/9999'
							label='Inclusão Serasa'
							placeholder='Informe a data'
							cols='12 6 3 2'
							readOnly={readOnly} />}
					</Row>}
					{this.props.formularioValues.id_tipo == 1 &&
					<Row>
						<Field
							name='scpc'
							component={LabelAndCheckbox}
							label='SCPC'
							placeholder='Informe'
							cols='12 4 2 1'
							readOnly={readOnly} />
							
						{this.props.formularioValues.scpc &&
						<Field
							name='data_scpc'
							component={LabelAndInputMask}
							mask='99/99/9999'
							label='Inclusão SCPC'
							placeholder='Informe a data'
							cols='12 6 3 2'
							readOnly={readOnly} />}
					</Row>}

					{this.props.formularioValues.itens && this.props.formularioValues.itens.length > 0 ? (
						<div>
							{this.props.formularioValues.itens.map((item, i) => {

								if (this.props.formularioValues.itens[i - 1] && this.props.formularioValues.itens[i - 1].id_forma_pagamento != item.id_forma_pagamento) {
									countFormaPagamento = 0;
								}

								countFormaPagamento++;

								return (
									<Row key={i}>
										<Select
											name='id_forma_pagamento'
											label={item.id_forma_pagamento == 1 || item.id_forma_pagamento == 2 || item.id_forma_pagamento == 4 //Boleto, Cartão de Crédito e Cheque
												? `${countFormaPagamento} - Forma de Pagamento` : 'Forma de Pagamento'}
											placeholder='Informe a forma de pagamento'
											cols='4 4 4 4'
											readOnly={readOnly}
											options={this.props.listaFormaPagamento}
											value={item.id_forma_pagamento}
											onChange={(value) => {
												this.props.initForm({
													...this.props.formularioValues,
													itens: this.props.formularioValues.itens.map((itemAtual, j) => {
														if (i == j) {
															return {
																...itemAtual,
																id_forma_pagamento: value
															}
														} else {
															return itemAtual
														}
													})
												});
											}} />

										{item.id_forma_pagamento == 1 || item.id_forma_pagamento == 4 ? (//Boleto e Cheque
											<LabelAndInputMask
												name='vencimento'
												label='Data de Vencimento'
												placeholder='Informe a data de vencimento'
												cols='3 3 3 3'
												mask='99/99/9999'
												readOnly={readOnly}
												value={item.data_vencimento}
												onChange={(value) => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map((itemAtual, j) => {
															if (i == j) {
																return {
																	...itemAtual,
																	data_vencimento: value.target.value
																}
															} else {
																return itemAtual
															}
														})
													});
												}} />
										) : null}

										{item.id_forma_pagamento == 2 || item.id_forma_pagamento == 4 ? (//Cartão de crédito e Cheque
											<LabelAndInputMask
												name='numero_cheque'
												label={`Número do ${item.id_forma_pagamento == 2 ? 'Cartão' : 'Cheque'}`}
												placeholder='Informe o número'
												cols='4 4 4 4'
												readOnly={readOnly}
												value={item.numero_cheque}
												onChange={(value) => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map((itemAtual, j) => {
															if (i == j) {
																return {
																	...itemAtual,
																	numero_cheque: value.target.value
																}
															} else {
																return itemAtual
															}
														})
													});
												}} />
										) : null}

										{item.id_forma_pagamento == 4 ? (//Cheque
											<LabelAndInputMask
												name='banco_cheque'
												label='Banco do Cheque'
												placeholder='Informe o banco'
												cols='4 4 4 4'
												readOnly={readOnly}
												value={item.banco_cheque}
												onChange={(value) => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map((itemAtual, j) => {
															if (i == j) {
																return {
																	...itemAtual,
																	banco_cheque: value.target.value
																}
															} else {
																return itemAtual
															}
														})
													});
												}} />
										) : null}

										{item.id_forma_pagamento == 8 || item.id_forma_pagamento == 9 ? (//Anuidade ou Outro
											<LabelAndInput
												name='descricao'
												label='Descrição'
												placeholder='Informe a descrição'
												cols='4 4 4 4'
												readOnly={readOnly}
												value={item.descricao}
												onChange={(value) => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map((itemAtual, j) => {
															if (i == j) {
																return {
																	...itemAtual,
																	descricao: value.target.value
																}
															} else {
																return itemAtual
															}
														})
													});
												}} />
										) : null}

										<LabelAndInputNumber
											name='valor'
											label='Valor'
											placeholder='Informe o valor'
											cols='4 4 4 4'
											readOnly={readOnly}
											value={parseFloat(item.valor || 0).toFixed(2).replace('.', ',')}
											onChange={(value) => {
												this.props.initForm({
													...this.props.formularioValues,
													itens: this.props.formularioValues.itens.map((itemAtual, j) => {
														if (i == j) {
															return {
																...itemAtual,
																valor: String(value.target.value || 0).replace(',', '.')
															}
														} else {
															return itemAtual
														}
													})
												});
											}} />

										{item.id_forma_pagamento == 8 || item.id_forma_pagamento == 9 ? (//Anuidade ou Outro
											<LabelAndInputMask
												name='data_pagamento'
												label='Data'
												placeholder='Informe a data'
												cols='3 3 3 3'
												mask='99/99/9999'
												readOnly={readOnly}
												value={item.data_pagamento}
												onChange={(value) => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map((itemAtual, j) => {
															if (i == j) {
																return {
																	...itemAtual,
																	data_pagamento: value.target.value
																}
															} else {
																return itemAtual
															}
														})
													});
												}} />
										) : null}

										{(item.id_forma_pagamento == 1 || item.id_forma_pagamento == 8 || item.id_forma_pagamento == 9) && //Boleto, Anuidade ou Outro
										<div style={{ paddingTop: 24 }}>
											<LabelAndCheckbox
												name='pago'
												label='Pago'
												placeholder='Informe'
												cols='2 2 2'
												readOnly={readOnly}
												value={item.pago || false}
												onChange={(value) => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map((itemAtual, j) => {
															if (i == j) {
																return {
																	...itemAtual,
																	pago: !itemAtual.pago
																}
															} else {
																return itemAtual
															}
														})
													});
												}} />
										</div>}

									</Row>
								)
							})}
						</div>
					) : null}

					{/*Adicionar Forma de Pagamento*/}
					{this.props.formularioValues.id_tipo == 2 || this.props.visualizar ? null : (
						<Row>
							<Select
								name='id_forma_pagamento'
								component={LabelAndInput}
								label='Adicionar Forma de Pagamento'
								placeholder='Informe a forma de pagamento'
								cols='12 4 4 4'
								readOnly={readOnly}
								options={this.props.listaFormaPagamento}
								value={this.props.formularioValues.id_forma_pagamento}
								onChange={(value) => {
									this.props.initForm({
										...this.props.formularioValues,
										id_forma_pagamento: value
									});
								}} />

							{this.props.formularioValues.id_forma_pagamento == 1 || this.props.formularioValues.id_forma_pagamento == 2
								|| this.props.formularioValues.id_forma_pagamento == 4 ? (//Boleto, Cartão de Crédito e Cheque
								<Select
									name='parcelas'
									component={LabelAndInput}
									label='Parcelas'
									placeholder='Informe a quantidade de parcelas'
									cols='12 4 4 4'
									readOnly={readOnly}
									options={
										this.props.formularioValues.id_forma_pagamento == 1 || this.props.formularioValues.id_forma_pagamento == 2 ?
										[
											{ id: 1, valor: 'À vista' }, { id: 2, valor: '2' }, { id: 3, valor: '3' }, { id: 4, valor: '4' },
											{ id: 5, valor: '5' }, { id: 6, valor: '6' }, { id: 7, valor: '7' }, { id: 8, valor: '8' },
											{ id: 9, valor: '9' }, { id: 10, valor: '10' }, { id: 11, valor: '11' }, { id: 12, valor: '12' },
											{ id: 13, valor: '13' }, { id: 14, valor: '14' }, { id: 15, valor: '15' }, { id: 16, valor: '16' }, 
											{ id: 17, valor: '17' }, { id: 18, valor: '18' }, { id: 19, valor: '19' }, { id: 20, valor: '20' }, 
											{ id: 21, valor: '21' }, { id: 22, valor: '22' }, { id: 23, valor: '23' }, { id: 24, valor: '24' }
										]
										:
										[
											{ id: 1, valor: 'À vista' }, { id: 2, valor: '2' }, { id: 3, valor: '3' }, { id: 4, valor: '4' },
											{ id: 5, valor: '5' }, { id: 6, valor: '6' }, { id: 7, valor: '7' }, { id: 8, valor: '8' },
											{ id: 9, valor: '9' }, { id: 10, valor: '10' }, { id: 11, valor: '11' }, { id: 12, valor: '12' }
										]
									}
									value={this.props.formularioValues.parcelas}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											parcelas: parseInt(value)
										});
									}} />
							) : null}

							{this.props.formularioValues.id_forma_pagamento == 1 || this.props.formularioValues.id_forma_pagamento == 4 ? (//Boleto e Cheque
								<Select
									name='dia_vencimento'
									component={LabelAndInput}
									label='Dia de Vencimento'
									placeholder='Informe o dia de vencimento'
									cols='12 4 4 4'
									readOnly={readOnly}
									options={[
										{ id: 1, valor: '01' }, { id: 2, valor: '02' }, { id: 3, valor: '03' }, { id: 4, valor: '04' }, { id: 5, valor: '05' },
										{ id: 6, valor: '06' }, { id: 7, valor: '07' }, { id: 8, valor: '08' }, { id: 9, valor: '09' }, { id: 10, valor: '10' },
										{ id: 11, valor: '11' }, { id: 12, valor: '12' }, { id: 13, valor: '13' }, { id: 14, valor: '14' }, { id: 15, valor: '15' },
										{ id: 16, valor: '16' }, { id: 17, valor: '17' }, { id: 18, valor: '18' }, { id: 19, valor: '19' }, { id: 20, valor: '20' },
										{ id: 21, valor: '21' }, { id: 22, valor: '22' }, { id: 23, valor: '23' }, { id: 24, valor: '24' }, { id: 25, valor: '25' },
										{ id: 26, valor: '26' }, { id: 27, valor: '27' }, { id: 28, valor: '28' }, { id: 29, valor: '29' }, { id: 30, valor: '30' },
										{ id: 31, valor: '31' }
									]}
									value={this.props.formularioValues.dia_vencimento}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											dia_vencimento: parseInt(value)
										});
									}} />
							) : null}
							{this.props.formularioValues.id_forma_pagamento == 1 || this.props.formularioValues.id_forma_pagamento == 4 ? (//Boleto e Cheque
								<Select
									name='mes_vencimento'
									component={LabelAndInput}
									label='Mês de Vencimento'
									placeholder='Informe o mês de vencimento'
									cols='12 4 4 4'
									readOnly={readOnly}
									options={[
										{ id: 0, valor: 'Janeiro' }, { id: 1, valor: 'Fevereiro' }, { id: 2, valor: 'Março' },
										{ id: 3, valor: 'Abril' }, { id: 4, valor: 'Maio' }, { id: 5, valor: 'Junho' },
										{ id: 6, valor: 'Julho' }, { id: 7, valor: 'Agosto' }, { id: 8, valor: 'Setembro' },
										{ id: 9, valor: 'Outubro' }, { id: 10, valor: 'Novembro' }, { id: 11, valor: 'Dezembro' }
									]}
									value={this.props.formularioValues.mes_vencimento}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											mes_vencimento: parseInt(value)
										});
									}} />
							) : null}
							{this.props.formularioValues.id_forma_pagamento == 1 || this.props.formularioValues.id_forma_pagamento == 4 ? (//Boleto e Cheque
								<Select
									name='ano_vencimento'
									component={LabelAndInput}
									label='Ano de Vencimento'
									placeholder='Informe o ano de vencimento'
									cols='12 4 4 4'
									readOnly={readOnly}
									options={[-4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((ano, i) => ({
										id: new Date().getFullYear() - ano,
										valor: new Date().getFullYear() - ano
									}))}
									value={this.props.formularioValues.ano_vencimento}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											ano_vencimento: value
										});
									}} />
							) : null}

							{this.props.formularioValues.id_forma_pagamento == 4 ? (//Cheque
								<Field
									name='numero_cheque'
									component={LabelAndInput}
									label='Número do Cheque'
									placeholder='Informe o número'
									cols='12 4 4 4'
									readOnly={readOnly}
									value={this.props.formularioValues.numero_cheque}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											numero_cheque: value
										});
									}} />
							) : null}

							{this.props.formularioValues.id_forma_pagamento == 4 ? (//Cheque
								<Field
									name='banco_cheque'
									component={LabelAndInput}
									label='Banco do Cheque'
									placeholder='Informe o banco'
									cols='12 4 4 4'
									readOnly={readOnly}
									value={this.props.formularioValues.banco_cheque}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											banco_cheque: value
										});
									}} />
							) : null}

							{this.props.formularioValues.id_forma_pagamento == 8 || this.props.formularioValues.id_forma_pagamento == 9 ? (//Anuidade ou Outro
								<LabelAndInput
									name='descricao'
									label='Descrição'
									placeholder='Informe a descrição'
									cols='4 4 4 4'
									readOnly={readOnly}
									value={this.props.formularioValues.descricao_forma_pagamento}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											descricao_forma_pagamento: value.target.value
										});
									}} />
							) : null}

							<LabelAndInputNumber
								name='valor'
								label='Valor'
								placeholder='Informe o valor'
								cols='4 4 4 4'
								readOnly={readOnly}
								value={parseFloat(this.props.formularioValues.valor_forma_pagamento || 0).toFixed(2).replace('.', ',')}
								onChange={(value) => {
									this.props.initForm({
										...this.props.formularioValues,
										valor_forma_pagamento: String(value.target.value || 0).replace(',', '.')
									});
								}} />

							
							{this.props.formularioValues.id_forma_pagamento == 8 || this.props.formularioValues.id_forma_pagamento == 9 ? (//Anuidade ou Outro
								<LabelAndInputMask
									name='data_pagamento'
									label='Data do Pagamento'
									placeholder='Informe a data'
									cols='3 3 3 3'
									mask='99/99/9999'
									readOnly={readOnly}
									value={this.props.formularioValues.data_pagamento_forma_pagamento}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											data_pagamento_forma_pagamento: value.target.value
										});
									}} />
							) : null}

							{(this.props.formularioValues.id_forma_pagamento == 8 || this.props.formularioValues.id_forma_pagamento == 9) && //Boleto
							<div style={{ paddingTop: 24 }}>
								<LabelAndCheckbox
									name='pago'
									label='Pago'
									placeholder='Informe'
									cols='2 2 2'
									readOnly={readOnly}
									value={this.props.formularioValues.pago_forma_pagamento || false}
									onChange={(value) => {
										this.props.initForm({
											...this.props.formularioValues,
											pago_forma_pagamento: !this.props.formularioValues.pago_forma_pagamento
										});
									}} />
							</div>}

							{this.props.formularioValues.id_forma_pagamento ? (
								<Grid cols='12 12 12 12' style={{ marginBottom: 20 }}>
									<Button
										text='Confirmar Forma de Pagamento'
										type={'primary'}
										icon={'fa fa-check'}
										event={() => {

											let itens = this.props.formularioValues.itens || [];
											let valor = this.props.formularioValues.valor_forma_pagamento || 0;
											let id_forma_pagamento = this.props.formularioValues.id_forma_pagamento;
											let dia_vencimento = this.props.formularioValues.dia_vencimento || 1;
											let mes_vencimento = this.props.formularioValues.mes_vencimento || new Date().getMonth();
											let ano_vencimento = this.props.formularioValues.ano_vencimento || new Date().getFullYear();
											let parcelas = this.props.formularioValues.parcelas || 1;

											if (valor == 0) {
												return;
											}

											if (id_forma_pagamento == 1) { //'Boleto'

												let dataAtual = new Date();
												dataAtual.setYear(ano_vencimento);
												dataAtual.setMonth(mes_vencimento);

												let dataVencimento = new Date(`${dataAtual.getFullYear()}/${dataAtual.getMonth() < 9 ? '0' : ''}${dataAtual.getMonth() + 1}/${dia_vencimento < 10 ? '0' : ''}${dia_vencimento}`);

												for (var i = 0; i < parcelas; i++) {
													itens.push({
														id_forma_pagamento: id_forma_pagamento,
														banco_cheque: null,
														numero_cheque: null,
														numero_cartao: null,
														titular: null,
														data_vencimento: DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(dataVencimento.getTime())),
														valor: parseFloat((valor / parcelas).toFixed(2)),
														pago: false
													});

													dataVencimento.setMonth(dataVencimento.getMonth() + 1);
												}

											} else if (id_forma_pagamento == 2) { //Cartão de Crédito

												for (var i = 0; i < parcelas; i++) {
													itens.push({
														id_forma_pagamento: id_forma_pagamento,
														banco_cheque: null,
														numero_cheque: null,
														numero_cartao: null,
														titular: null,
														data_vencimento: null,
														valor: parseFloat((valor / parcelas).toFixed(2)),
														pago: false
													});
												}

											} else if (id_forma_pagamento == 3) { //Cartão de Débito

												itens.push({
													id_forma_pagamento: id_forma_pagamento,
													banco_cheque: null,
													numero_cheque: null,
													numero_cartao: null,
													titular: null,
													data_vencimento: null,
													valor: valor,
													pago: false
												});

											} else if (id_forma_pagamento == 4) { //Cheque

												let dataAtual = new Date();
												dataAtual.setYear(ano_vencimento);
												dataAtual.setMonth(mes_vencimento);

												let dataVencimento = new Date(`${dataAtual.getFullYear()}/${dataAtual.getMonth() < 9 ? '0' : ''}${dataAtual.getMonth() + 1}/${dia_vencimento < 10 ? '0' : ''}${dia_vencimento}`);

												for (var i = 0; i < parcelas; i++) {
													itens.push({
														id_forma_pagamento: id_forma_pagamento,
														banco_cheque: this.props.formularioValues.banco_cheque,
														numero_cheque: this.props.formularioValues.numero_cheque,
														numero_cartao: null,
														titular: null,
														data_vencimento: DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(dataVencimento.getTime())),
														valor: parseFloat((valor / parcelas).toFixed(2)),
														pago: false
													});

													dataVencimento.setMonth(dataVencimento.getMonth() + 1);
												}

											} else if (id_forma_pagamento == 5 || id_forma_pagamento == 6 || id_forma_pagamento == 7) { //Dinheiro, Pix e Depósito

												itens.push({
													id_forma_pagamento: id_forma_pagamento,
													banco_cheque: null,
													numero_cheque: null,
													numero_cartao: null,
													titular: null,
													data_vencimento: null,
													valor: valor,
													pago: false
												});

											} else if (id_forma_pagamento == 8 || id_forma_pagamento == 9) { //Anuidade ou Outro
												itens.push({
													id_forma_pagamento: id_forma_pagamento,
													banco_cheque: null,
													numero_cheque: null,
													numero_cartao: null,
													titular: null,
													data_vencimento: null,
													valor: valor,
													data_pagamento: this.props.formularioValues.data_pagamento_forma_pagamento,
													descricao: this.props.formularioValues.descricao_forma_pagamento || '',
													pago: this.props.formularioValues.pago_forma_pagamento || false
												});														
														
											}

											let valorNovo = 2340;
											for (var i = 0; i < itens.length; i++) {
												let item = itens[i];

												valorNovo -= item.valor;
											}

											this.props.initForm({
												...this.props.formularioValues,
												itens: itens,
												id_forma_pagamento: null,
												valor_forma_pagamento: valorNovo > 0 ? valorNovo : 0
											})

										}} />
								</Grid>
							) : null}

						</Row>
					)}

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Voltar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>

				{this.props.formularioValues.id && this.props.formularioValues.id_tipo == 1 ? (
					<label style={{ marginTop: 20, marginBottom: 10, fontSize: 18 }}>Documentos</label>
				) : null}

				{this.props.formularioValues.id && this.props.formularioValues.id_tipo == 1 ? (
					<div className='form-group'>
						<div className='custom-file'>
							<input
								className='custom-file-input'
								type='file'
								id='customFile'
								value={this.state.file}
								onChange={event => {
									event.preventDefault();
									let reader = new FileReader();
									let file = event.target.files[0];

									reader.onloadend = () => {

										this.props.importarArquivo(this.props.formularioValues, reader.result);

										this.setState({ ...this.state, file: null })

									}

									reader.readAsDataURL(file);

								}} />
						</div>
					</div>
				) : null}

				{this.props.formularioValues.id && this.props.formularioValues.id_tipo == 1 ? (

					<Table>
						<THead>
							<Tr>
								<Th>Arquivo</Th>
								<Th alignCenter acoes>Ações</Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaDocumento.map(item => (
								<Tr key={item.id}>
									<Td alignLeft minWidth={50}>
										<ButtonTable
											type={'primary'}
											icon={'fa fa-download'}
											visible={true}
											event={() => {
												const linkSource = item.arquivo;
												const downloadLink = document.createElement("a");
												const fileName = `arquivo.${item.arquivo.split('/')[1].split(';')[0]}`;
												downloadLink.href = linkSource;
												downloadLink.download = fileName;
												downloadLink.click();
											}} />
									</Td>
									<Td alignCenter>
										<ButtonTable
											type={'danger'}
											icon={'fa fa-trash-o'}
											event={() => {
												this.props.excluirArquivo(item);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>

				) : null}

				<div style={{ marginTop: 500 }}></div>

			</ContentCard>
		)
	}
}

ClienteForm = reduxForm({ form: 'clienteForm', destroyOnUnmount: false })(ClienteForm);
const mapStateToProps = state => ({
	registro: state.cliente.registro,
	listaClienteTipo: state.cliente.listaClienteTipo,
	listaClienteStatus: state.cliente.listaClienteStatus,
	listaEstadoCivil: state.cliente.listaEstadoCivil,
	listaFormaPagamento: state.cliente.listaFormaPagamento,
	listaOperadoraTelefonica: state.cliente.listaOperadoraTelefonica,
	listaTipoContrato: state.cliente.listaTipoContrato,
	listaUsuario: state.cliente.listaUsuario,
	listaEstado: state.cliente.listaEstado,
	listaCidade: state.cliente.listaCidade,
	listaDocumento: state.cliente.listaDocumento,
	listaFormaContrato: state.cliente.listaFormaContrato,
	formularioValues: getFormValues('clienteForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, importarArquivo, excluirArquivo, limparAcesso, salvar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteForm);
